import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    private isBrowser: boolean = false;

    public constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.isBrowser = true;
        }
    }

    public isPlatformBrowser(): boolean {
        return this.isBrowser;
    }

    public isPlatformServer(): boolean {
        return !this.isBrowser;
    }
}
