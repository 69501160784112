import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowSize } from '../api/window-size';
import { WindowService } from './window.service';
import { WindowExtension } from '../api/window-extension';

@Injectable({
    providedIn: 'root'
})
export class WindowResizeService {
    private sizeSubject: BehaviorSubject<WindowSize>;
    private windowExtension: WindowExtension;

    public constructor(
        private readonly windowService: WindowService
    ) {
        this.windowExtension = this.windowService.getWindowExtension();
        this.sizeSubject = new BehaviorSubject<WindowSize>({
            width: this.windowExtension.innerWidth,
            height: this.windowExtension.innerHeight
        });
    }

    public getSize(): BehaviorSubject<WindowSize> {
        return this.sizeSubject;
    }

    public setSize(size: WindowSize): void {
        this.sizeSubject.next(size);
    }
}
