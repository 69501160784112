@if (isRequired) {
<div [class.start]="isFullyLoaded" [class.accepted]="isAccepted" class="container">
    <h2>
        <span i18n>Wykorzystujemy pliki </span>
        <q i18n>Cookies</q>
        <mat-icon>cookie</mat-icon>
    </h2>
    <p>
        <span i18n>Strona korzysta z plików </span>
        <q i18n>Cookies</q>
        <span i18n> w celu realizacji usług. Możesz określić warunki zapisywania i obsługi plików </span>
        <q i18n>Cookies</q>
        <span i18n> w Twojej przeglądarce. Szczegóły znajdziesz w naszej </span>
        <a [routerLink]="'privacyPolicy' | urlPipe"><span i18n>Polityce
                prywatności</span><mat-icon>open_in_new</mat-icon></a>.
    </p>
    <div class="actions">
        <button aria-label="Odrzuć" i18n-aria-label class="ui-button error" (click)="decline()" type="button"
            i18n>Odrzuć</button>
        <button aria-label="Szczegóły" class="ui-button warn" (click)="openDetails()" i18n-aria-label type="button"
            i18n>Szczegóły</button>
        <button aria-label="Akceptuję" class="ui-button acceptance" i18n-aria-label type="button" i18n
            (click)="accept()">Akceptuję</button>
    </div>
</div>
}
