<ng-content></ng-content>
@if (isLoading) {
<svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" [class.left]="isLeft" [class.white]="loadingColor === 'white'"
    [class.black]="loadingColor === 'black'">
    <g transform="rotate(0 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s" begin="-0.6s"
                repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(36 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.5333333333333333s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(72 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.4666666666666667s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(108 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s" begin="-0.4s"
                repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(144 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(180 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.26666666666666666s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(216 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s" begin="-0.2s"
                repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(252 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.13333333333333333s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(288 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s"
                begin="-0.06666666666666667s" repeatCount="indefinite"></animate>
        </rect>
    </g>
    <g transform="rotate(324 50 50)">
        <rect x="47" y="24" rx="3" ry="4.2" width="6" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.6666666666666666s" begin="0s"
                repeatCount="indefinite"></animate>
        </rect>
    </g>
</svg>
}
