import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { cookieLocalstorageType } from '../api/cookie-or-localstorage-type';
import { cookieLocalstorageItem } from '../api/cookie-localstorage-item';
import { CookieLocalstorageHelperService } from './cookie-localstorage-helper.service';
import { cookiePreferenceKey } from '../models/cookie-preference-key';
import { LocalStorageService } from './local-storage.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

@Injectable({
    providedIn: 'root'
})
export class CookieLocalstorageRemoverService {
    private readonly preferenceKey: string = cookiePreferenceKey;

    public constructor(
        private readonly cookieService: CookieService,
        private readonly cookieLocalstorageHelperService: CookieLocalstorageHelperService,
        private readonly localStorageService: LocalStorageService,
        private readonly googleAnalyticsService: GoogleAnalyticsService
    ) { }

    public removeRequired(): void {
        this.removeGroup('required');
    }

    public removeFunctional(): void {
        this.removeGroup('functional');
    }

    public removePerformance(): void {
        this.removeGroup('performance');
    }

    public removeAds(): void {
        this.removeGroup('ads');
        this.googleAnalyticsService.analyticsEnable(false);
    }

    public removeAllCategoriesKeys(): void {
        this.localStorageService.removeItem(this.preferenceKey);
        this.googleAnalyticsService.analyticsEnable(true);
    }

    private removeGroup(group: string): void {
        this.cookieLocalstorageHelperService.getCookieItems().forEach((cookieGroup: cookieLocalstorageItem) => {
            if (cookieGroup.group === group) {
                this.searchAndDeleteItems(cookieGroup.items, 'cookie');
            }
        });

        this.cookieLocalstorageHelperService.getLocalstorageItems().forEach((localstorageGroup: cookieLocalstorageItem) => {
            if (localstorageGroup.group === group) {
                this.searchAndDeleteItems(localstorageGroup.items, 'localstorage');
            }
        });
    }

    private searchAndDeleteItems(items: string[], type: cookieLocalstorageType): void {
        items.forEach((item: string) => {
            if (type === 'cookie') {
                if (item.endsWith('.*')) {
                    this.deleteCookiesWithPrefix(item.slice(0, -2));
                } else {
                    this.deleteCookie(item);
                }
            } else {
                if (item.endsWith('.*')) {
                    this.deleteLocalstorageWithPrefix(item.slice(0, -2));
                } else {
                    this.deleteLocalstorage(item);
                }
            }
        });
    }

    private deleteLocalstorage(name: string): void {
        this.localStorageService.removeItem(name);
    }

    private deleteCookie(name: string): void {
        this.cookieService.delete(name);
    }

    private deleteCookiesWithPrefix(prefix: string): void {
        const allCookies: string[] = this.cookieService.getAll();

        Object.keys(allCookies).forEach((cookieName: string) => {
            if (cookieName.startsWith(prefix)) {
                this.cookieService.delete(cookieName);
            }
        });
    }

    private deleteLocalstorageWithPrefix(prefix: string): void {
        const allKeys: string[] = Object.keys(localStorage);

        allKeys.forEach((key: string) => {
            if (key.startsWith(prefix)) {
                this.localStorageService.removeItem(key);
            }
        });
    }
}
