import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './routes';
import { environment } from 'src/environments/environment';
import { provideClientHydration, withEventReplay, withI18nSupport } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { scrollConfig } from './scroll-config';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from 'src/app/locale/providers/paginator-intl';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withInMemoryScrolling(scrollConfig),
        ),
        importProvidersFrom([
            BrowserAnimationsModule,
            MatNativeDateModule
        ]),
        {
            provide: LOCALE_ID,
            useValue: environment.locale.id
        },
        {
            provide: MatPaginatorIntl,
            useValue: CustomPaginatorIntl.getPaginatorIntl()
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { autoFocus: 'dialog', restoreFocus: true }
        },
        provideClientHydration(
            withI18nSupport(),
            withEventReplay(),
        ),
        provideHttpClient(withFetch())
    ]
};
