import { cookieLocalstorageItem } from '../api/cookie-localstorage-item';

export const cookieItems: cookieLocalstorageItem[] = [
    {
        group: 'required',
        items: [
            'auth'
        ]
    },
    {
        group: 'functional',
        items: [
            'theme',
        ]
    },
];
