import { Injectable } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';

@Injectable({
    providedIn: 'root'
})
export class AclMatcherService {
    public static isLoggedIn(): boolean {
        return AuthorizationService.isLoggedIn();
    }

    public static isLoggedOut(): boolean {
        return !AuthorizationService.isLoggedIn();
    }

    public static isAdmin(): boolean {
        return AuthorizationService.hasRole(AuthorizationService.adminRole);
    }

    public static isEmployee(): boolean {
        return AuthorizationService.hasRole(AuthorizationService.employeeRole);
    }
}
