import { Pipe, PipeTransform } from '@angular/core';
import { PathService } from '../services/path.service';

@Pipe({
    standalone: true,
    name: 'urlPipe'
})
export class UrlPipe implements PipeTransform {
    public constructor(
        private readonly pathService: PathService
    ) { }

    public transform(url: string): string {
        return this.pathService.getUrlForPath(url);
    }
}
