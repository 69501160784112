import { LoginStatusService } from './../../core/services/login-status.service';
import { Injectable } from '@angular/core';
import { OnFinish } from 'src/app/core/api/on-finish';
import { ApiService } from 'src/app/core/services/api.service';
import { Credentials } from '../api/credentials';
import { Auth } from '../api/auth';
import { RecaptchaService } from 'src/app/ui/services/recaptcha.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/cookie/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly apiPath: string = 'auths';

    public constructor(
        private readonly apiService: ApiService,
        private readonly recaptchaService: RecaptchaService,
        private readonly loginStatusService: LoginStatusService,
        private readonly localStorageService: LocalStorageService
    ) { }

    public getIRI(id: number): string {
        return this.apiService.apiUrl + this.apiPath + '/' + id;
    }

    public post(
        data: Credentials,
        onSuccess: OnFinish<Auth>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.recaptchaService.prepareTokenForAction(
            'postAuth',
            () => {
                this.apiService.post(
                    this.apiPath,
                    data,
                    (auth: Auth) => {
                        this.loginStatusService.setLoginStatus(auth.token ? true : false);

                        return onSuccess(auth);
                    },
                    onError,
                    false
                );
            }
        );
    }

    public delete(
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.delete(
            this.apiPath + '/' + this.localStorageService.getItem('token'),
            {},
            onSuccess,
            onError
        );
    }

    public ping(
        onSuccess: OnFinish<Auth>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.patch(
            this.apiPath + '/' + this.localStorageService.getItem('token') + '/ping',
            {},
            (auth: Auth) => {
                this.loginStatusService.setLoginStatus(true);

                return onSuccess(auth);
            },
            (error: HttpErrorResponse) => {
                this.loginStatusService.setLoginStatus(false);

                return onError(error);
            }
        );
    }
}
