import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { CookieLocalstorageRemoverService } from 'src/app/cookie/services/cookie-localstorage-remover.service';
import { UrlPipe } from 'src/app/locale/pipes/url-pipe';
import { ButtonComponent } from 'src/app/ui/components/button/button.component';
import { CookiePopupDetailsComponent } from '../cookie-popup-details/cookie-popup-details.component';
import { first, timer } from 'rxjs';
import { cookiePreferenceAllDisabled } from '../../models/cookie-preference-all-disabled';
import { LocalStorageService } from '../../services/local-storage.service';
import { PlatformService } from 'src/app/core/services/platform.service';

@Component({
    standalone: true,
    imports: [
        MatIconModule,
        RouterLink,
        UrlPipe,
        ButtonComponent
    ],
    selector: '.ui-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements AfterViewInit {
    private readonly localStorageKeyCookiePopup: string = 'cookie-popup';

    public isRequired: boolean = false;
    public isAccepted: boolean = false;
    public isFullyLoaded: boolean = false;

    public constructor(
        private readonly localStorageService: LocalStorageService,
        private readonly cookieLocalstorageRemoverService: CookieLocalstorageRemoverService,
        private readonly dialog: MatDialog,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platformService: PlatformService
    ) { }

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        this.isRequired = !(this.localStorageService.getItem(this.localStorageKeyCookiePopup) === 'accepted');

        if (this.isRequired) {
            timer(500).pipe(
                first()
            ).subscribe(() => {
                this.isFullyLoaded = true;
                this.changeDetectorRef.detectChanges();
            });
        }

        this.changeDetectorRef.detectChanges();
    }

    public accept(): void {
        this.isAccepted = true;
        this.cookieLocalstorageRemoverService.removeAllCategoriesKeys();
        this.setCookiePopupAccepted();
    }

    public openDetails(): void {
        this.isAccepted = true;

        this.dialog.open(CookiePopupDetailsComponent,
            {
                maxWidth: '600px',
                width: '100%'
            }
        ).afterClosed().pipe(
            first()
        ).subscribe((result: boolean) => {
            if (!result) {
                this.isAccepted = false;
            } else {
                this.setCookiePopupAccepted();
            }
        });
    }

    public decline(): void {
        this.isAccepted = true;
        this.localStorageService.setPreference(cookiePreferenceAllDisabled);
        this.cookieLocalstorageRemoverService.removeFunctional();
        this.cookieLocalstorageRemoverService.removePerformance();
        this.setCookiePopupAccepted();
    }

    private setCookiePopupAccepted(): void {
        this.localStorageService.setItem(this.localStorageKeyCookiePopup, 'accepted');
    }
}
