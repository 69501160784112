import { Component } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../api/notification';
import { NotificationType } from '../../api/notification-type';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtmlPipe } from '../../pipes/safe-html-pipe';

@Component({
    standalone: true,
    imports: [
        MatIconModule,
        SafeHtmlPipe
    ],
    selector: '.core-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent {
    public notifications: Notification[] = [];

    public constructor(
        private readonly notificationService: NotificationService
    ) {
        this.notifications = this.notificationService.getNotifications();
    }

    public closeNotification(notification: Notification): void {
        this.notificationService.dismissNotification(notification);
    }

    public getNotificationIcon(notification: Notification): string {
        switch (notification.type) {
            case NotificationType.success:
                return 'check_circle';
            case NotificationType.error:
                return 'error';
            case NotificationType.warning:
                return 'warning';
            default:
                return 'info';
        }
    }
}
