import { Component } from '@angular/core';
import { PageLoaderService } from '../../services/page-loader.service';

@Component({
    standalone: true,
    selector: '.core-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {
    public constructor(
        private readonly pageLoaderService: PageLoaderService
    ) { }

    public getProgress(): string {
        return this.pageLoaderService.getProgress() + '%';
    }

    public isActive(): boolean {
        const progress = this.pageLoaderService.getProgress();

        return progress > 0 && progress < 100;
    }

    public shouldAnimate() {
        return this.pageLoaderService.getShouldAnimate();
    }
}
