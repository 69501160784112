import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, first } from 'rxjs';
import { OnFinish } from '../api/on-finish';
import { AuthorizationService } from './authorization.service';
import { ApiOptions } from '../api/api-options';
import { ApiFilterConfig } from '../api/api-filter-config';
import { ApiCleanResponse } from '../api/api-clean-response';
import { ApiResponse } from '../api/api-response';
import { UuidService } from './uuid.service';
import { DateFormatterService } from 'src/app/date/services/date-formatter.service';
import { ApiPrepareData } from '../api/api-prepare-data';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public readonly apiUrl: string = '/api/';

    private recaptchaToken?: string;
    private contentType?: string = 'application/json';

    public constructor(
        @Inject(LOCALE_ID) private readonly localeId: string,
        private readonly http: HttpClient,
        private readonly authorizationService: AuthorizationService,
        private readonly uuidService: UuidService,
        private readonly dateFormatterService: DateFormatterService,
    ) { }

    public setRecaptchaToken(token: string): void {
        this.recaptchaToken = token;
    }

    public setMultipartContentType(): void {
        this.contentType = undefined;
    }

    public prepareGet(endpoint: string, data: Record<string, string>): Observable<Object> {
        return this.http
            .get(this.apiUrl + endpoint + this.getUriParams(data), this.getOptions());
    }

    public prepareGetCollection(
        endpoint: string,
        data: Record<string, string>,
        apiFilterConfig: ApiFilterConfig
    ): Observable<ApiCleanResponse<Object>> {
        data['page'] = apiFilterConfig.page;
        data['itemsPerPage'] = apiFilterConfig.itemsPerPage;
        if (apiFilterConfig.filter) {
            data['filter'] = apiFilterConfig.filter;
        }

        if (apiFilterConfig.sortField) {
            data[`order[${apiFilterConfig.sortField}]`] = apiFilterConfig.sortDirection ?? 'asc';
        }

        return this.http.get(
            this.apiUrl + endpoint + this.getUriParams(data),
            this.getOptions('application/ld+json')
        ) as Observable<ApiCleanResponse<Object>>;
    }

    public preparePost(endpoint: string, data: object): Observable<Object> {
        return this.http
            .post(this.apiUrl + endpoint, data, this.getOptions());
    }

    public preparePatch(endpoint: string, data: object): Observable<Object> {
        return this.http
            .patch(this.apiUrl + endpoint, data, this.getOptions());
    }

    public preparePatchWithQueryParams(
        endpoint: string,
        queryParams: Record<string, string>,
        data: object
    ): Observable<Object> {
        return this.http.patch(this.apiUrl + endpoint + this.getUriParams(queryParams), data, this.getOptions());
    }

    public preparePut(endpoint: string, data: object): Observable<Object> {
        return this.http
            .put(this.apiUrl + endpoint, data, this.getOptions());
    }

    public prepareDelete(endpoint: string, data: Record<string, string>): Observable<Object> {
        return this.http
            .delete(this.apiUrl + endpoint + this.getUriParams(data), this.getOptions());
    }

    public get(
        endpoint: string,
        data: Record<string, string>,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.prepareGet(endpoint, data).pipe(
            first()
        ).subscribe({
            next: onSuccess,
            error: this.prepareOnError(onError)
        });
    }

    public getCollection(
        endpoint: string,
        data: Record<string, string>,
        apiFilterConfig: ApiFilterConfig,
        onSuccess: OnFinish<ApiResponse<any>>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.prepareGetCollection(endpoint, data, apiFilterConfig).pipe(
            first()
        ).subscribe({
            next: (apiCleanResponse: ApiCleanResponse<any>) => {
                const total: number = apiCleanResponse['hydra:totalItems'],
                    apiResponse: ApiResponse<any> = {
                        data: apiCleanResponse['hydra:member'],
                        totalItems: total,
                        totalPages: Math.ceil(total / parseInt(apiFilterConfig.itemsPerPage))
                    }

                onSuccess(apiResponse);
            },
            error: this.prepareOnError(onError)
        });
    }

    public post(
        endpoint: string,
        data: object,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>,
        shouldGenerateUuid: boolean = true
    ): void {
        const preparedData: ApiPrepareData = this.prepareData(data, shouldGenerateUuid);

        this.preparePost(endpoint, preparedData.data)
            .pipe(first())
            .subscribe({
                next: (response) => onSuccess({
                    ...response,
                    uuid: preparedData.uuid
                }),
                error: this.prepareOnError(onError),
            });
    }


    public patch(
        endpoint: string,
        data: object,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.preparePatch(endpoint, this.prepareData(data).data).pipe(
            first()
        ).subscribe({
            next: onSuccess,
            error: this.prepareOnError(onError)
        });
    }

    public patchWithQueryParams(
        endpoint: string,
        queryParams: Record<string, string>,
        data: object,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.preparePatchWithQueryParams(
            endpoint,
            queryParams,
            data
        ).pipe(
            first()
        ).subscribe({
            next: onSuccess,
            error: this.prepareOnError(onError)
        });
    }

    public put(
        endpoint: string,
        data: object,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.preparePut(endpoint, data).pipe(
            first()
        ).subscribe({
            next: onSuccess,
            error: this.prepareOnError(onError)
        });
    }

    public delete(
        endpoint: string,
        data: Record<string, string>,
        onSuccess: OnFinish<any>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.prepareDelete(endpoint, data).pipe(
            first()
        ).subscribe({
            next: onSuccess,
            error: this.prepareOnError(onError)
        });
    }

    public getUuidFromIRI(iri: string): string {
        const parts: string[] = iri.split('/');

        return parts[parts.length - 1];
    }

    private prepareOnError(onError: OnFinish<HttpErrorResponse>): OnFinish<HttpErrorResponse> {
        return (response: HttpErrorResponse): void => {
            if (response.status < 200 || response.status > 599 || response.status === 499) {
                // most probably client closed request
                return;
            }

            onError(response);
        }
    }

    private getOptions(accept: string = 'application/json'): ApiOptions {
        let headers: HttpHeaders = new HttpHeaders()
            .set('Accept', accept)
            .set('Accept-Language', this.localeId)
            .set('Authorization', this.authorizationService.getAuthorization())

        if (this.contentType) {
            headers = headers.set('Content-Type', this.contentType);
        }
        if (this.recaptchaToken) {
            headers = headers.set('Recaptcha-Token', this.recaptchaToken);
        }

        this.recaptchaToken = undefined;
        this.contentType = 'application/json';

        return {
            observe: 'body',
            responseType: 'json',
            headers: headers
        };
    }

    private getUriParams(data: Record<string, string>): string {
        const searchParams: URLSearchParams = new URLSearchParams(data);

        return '?' + searchParams.toString();
    }

    private prepareData(data: Record<string, any>, shouldGenerateUuid: boolean = false): ApiPrepareData {
        data = this.formatDates(data);

        if (shouldGenerateUuid) {
            const uuid: string = this.uuidService.generate();

            if (data instanceof FormData) {
                data.append('uuid', uuid);
            } else {
                data['uuid'] = uuid;
            }

            return {
                uuid: uuid,
                data: data
            };
        }

        return {
            data: data
        };
    }

    private formatDates(obj: Record<string, any>): Record<string, any> {
        for (const key in obj) {
            if (obj[key] instanceof Date) {
                obj[key] = this.dateFormatterService.getFormattedDate(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                obj[key] = this.formatDates(obj[key]);
            }
        }

        return obj;
    }
}
