import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AuthService } from './auth.service';
import { Subscription, interval } from 'rxjs';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PathService } from 'src/app/locale/services/path.service';
import { Credentials } from '../api/credentials';
import { OnFinish } from 'src/app/core/api/on-finish';
import { Auth } from '../api/auth';
import { RoleService } from './role.service';
import { Role } from '../api/role';
import { UserService } from './user.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';
import { WindowService } from 'src/app/core/services/window.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService implements OnDestroy {
    private readonly pingInterval: number = 5 * 60 * 1000;

    private pingSubscription?: Subscription;
    private document: Document;

    public constructor(
        private readonly authService: AuthService,
        private readonly roleService: RoleService,
        private readonly authorizationService: AuthorizationService,
        private readonly router: Router,
        private readonly pathService: PathService,
        private readonly notificationService: NotificationService,
        private readonly userService: UserService,
        private readonly googleAnalyticsService: GoogleAnalyticsService,
        private readonly windowService: WindowService,
        private readonly ngZone: NgZone,
    ) {
        this.document = this.windowService.getDocument();
    }

    public ngOnDestroy(): void {
        this.pingSubscription?.unsubscribe();
    }

    public init(): void {
        if (this.authorizationService.isLoggedIn()) {
            this.roleService.getCollectionForUser(
                (roles: Role[]) => {
                    this.authorizationService.setRoles(roles);
                    this.startPingInterval();
                    this.ping();
                    this.userService.refreshLoggedUserCache();
                },
                () => {
                    this.logoutForced();
                }
            )
        }
    }

    public login(
        credentials: Credentials,
        onComplete: OnFinish<void>,
        onIncorrectCredentials: OnFinish<HttpErrorResponse>
    ): void {
        this.authService.post(
            credentials,
            (auth: Auth) => {
                this.authorizationService.setAuthorization(auth.token);
                this.roleService.getCollectionForUser(
                    (roles: Role[]) => {
                        this.userService.refreshLoggedUserCache();
                        this.authorizationService.setRoles(roles);
                        this.router.navigateByUrl(this.pathService.getUrlForPath('customer'));
                        this.googleAnalyticsService.login();
                        this.notificationService.addSuccessNotification($localize`Pomyślnie zalogowano!`);
                        this.startPingInterval();
                        onComplete();
                    },
                    () => {
                        this.authorizationService.removeAuthorization();
                        this.notificationService.addServerError();
                        onComplete();
                    }
                )
            },
            (error: HttpErrorResponse) => {
                if (error.status == 404 || error.status == 422 || error.status == 424 || error.status == 425) {
                    onIncorrectCredentials(error);
                } else {
                    this.notificationService.addServerError();
                }
                onComplete();
            }
        )
    }

    public logout(): void {
        this.authService.delete(
            () => {
                this.logoutCommon();
                this.router.navigateByUrl('/');
                this.googleAnalyticsService.logout();
                this.notificationService.addSuccessNotification($localize`Pomyślnie wylogowano!`);
            },
            (error: HttpErrorResponse) => {
                if (error.status === 404 || error.status === 401) {
                    this.logoutForced();
                } else {
                    this.notificationService.addServerError();
                }
            }
        );
    }

    public logoutCommon(): void {
        this.stopPingInterval();
        this.authorizationService.removeAuthorization();
        this.authorizationService.removeRoles();
        this.userService.clearLoggedUserCache();

        const overlays: NodeListOf<HTMLElement> = this.document.querySelectorAll('.overlay, .cdk-overlay-backdrop');

        for (let i = 0; i < overlays.length; i++) {
            overlays[i].click();
        }
    }

    private startPingInterval(): void {
        this.ngZone.runOutsideAngular(() => {
            this.pingSubscription = interval(this.pingInterval).subscribe(() => {
                this.ngZone.run(() => this.ping());
            });
        });
    }

    private stopPingInterval(): void {
        this.pingSubscription?.unsubscribe();
    }

    private ping(): void {
        this.authService.ping(
            () => { },
            () => {
                this.logoutForced();
            }
        );
    }

    private logoutForced(): void {
        this.logoutCommon();
        this.router.navigateByUrl(this.pathService.getUrlForPath('login'));
        this.notificationService.addWarningNotification($localize`Sesja wygasła! Zaloguj się ponownie.`);
    }
}
