import { LocalizedPath } from '../api/localized-path';

export const Paths: LocalizedPath = {
    pl: {
        base: '/',
        offer: 'oferta',
        about: 'o-nas',
        career: 'kariera',
        contact: 'kontakt',
        login: 'logowanie',
        register: 'stworz-konto',
        remindPassword: 'przypomnij-haslo',
        setPassword: 'ustaw-haslo',
        confirmEmail: 'potwierdz-email',
        regulations: 'regulamin',
        privacyPolicy: 'polityka-prywatnosci',
        gdpr: 'rodo',
        confirmAccountDeletion: 'potwierdz-usuniecie-konta',
        changeEmail: 'zmien-email',
        customer: {
            base: 'klient',
            settings: {
                base: 'ustawienia',
                contact: 'kontakt',
                password: 'haslo',
                address: 'adres',
                notifications: 'powiadomienia',
                display: 'wyswietlanie',
                advanced: 'zaawansowane',
            },
            notification: {
                base: 'powiadomienia',
                new: 'nowe',
                archive: 'archiwum'
            },
            projects: {
                base: 'projekty',
                all: 'wszystkie',
                planning: 'planowane',
                ongoing: 'trwajace',
                finished: 'ukonczone',
                canceled: 'anulowane',
                details: 'szczegoly',
            },
            services: {
                base: 'uslugi',
                domains: 'domeny',
                domainDetails: 'domena-szczegoly',
                hosting: 'hosting',
                hostingDetails: 'hosting-szczegoly',
                servers: 'serwery',
                serverDetails: 'serwer-szczegoly',
                ssl: 'ssl',
                sslDetails: 'ssl-szczegoly',
                marketing: 'marketing',
                marketingDetails: 'marketing-szczegoly',
                helpDesk: 'wsparcie-techniczne',
                helpDeskDetails: 'wsparcie-techniczne-szczegoly',
                emails: 'adresy-email',
                emailDetails: 'adres-email-szczegoly',
                seo: 'pozycjonowanie',
                seoDetails: 'pozycjonowanie-szczegoly',
                smartWebsites: 'smart-websites',
                smartWebsiteDetails: 'smart-website-szczegoly',
            },
            invoices: {
                base: 'faktury',
                all: 'wszystkie',
                expired: 'przeterminowane',
                paid: 'zaplacone',
                toPay: 'do-zaplaty'
            },
        },
        admin: {
            base: 'admin',
            projects: {
                base: 'projekty',
                active: 'aktywne',
                inactive: 'archiwum',
                details: 'szczegoly'
            },
            invoices: {
                base: 'faktury',
                active: 'aktywne',
                inactive: 'archiwum',
                details: 'szczegoly',
            },
            services: {
                base: 'uslugi',
                inactive: 'archiwum',
                domains: 'domeny',
                domainDetails: 'domena-szczegoly',
                hosting: 'hosting',
                hostingDetails: 'hosting-szczegoly',
                servers: 'serwery',
                serverDetails: 'serwer-szczegoly',
                ssl: 'ssl',
                sslDetails: 'ssl-szczegoly',
                marketing: 'marketing',
                marketingDetails: 'marketing-szczegoly',
                helpDesk: 'wsparcie-techniczne',
                helpDeskDetails: 'wsparcie-techniczne-szczegoly',
                emails: 'adresy-email',
                emailDetails: 'adres-email-szczegoly',
                seo: 'pozycjonowanie',
                seoDetails: 'pozycjonowanie-szczegoly',
                websites: 'strony-internetowe',
                websiteDetails: 'strona-internetowa-szczegoly',
            },
            users: {
                base: 'uzytkownicy',
                active: 'aktywni',
                ban: 'zablokowani',
                details: 'szczegoly',
                ipBan: 'zablokowane-ip',
                deleted: 'usunieci',
            }
        },
    },
    'en-US': {
        base: '/en-US/',
        offer: 'offer',
        about: 'about',
        career: 'career',
        contact: 'contact',
        login: 'login',
        register: 'create-account',
        remindPassword: 'remind-password',
        setPassword: 'set-password',
        confirmEmail: 'confirm-email',
        regulations: 'regulations',
        privacyPolicy: 'privacy-policy',
        gdpr: 'gdpr',
        confirmAccountDeletion: 'confirm-account-deletion',
        changeEmail: 'change-email',
        customer: {
            base: 'customer',
            settings: {
                base: 'settings',
                contact: 'contact',
                password: 'password',
                address: 'address',
                notifications: 'notifications',
                display: 'display',
                advanced: 'advanced',
            },
            notification: {
                base: 'notifications',
                new: 'new',
                archive: 'archive'
            },
            projects: {
                base: 'projects',
                all: 'all',
                planning: 'planning',
                ongoing: 'ongoing',
                finished: 'finished',
                canceled: 'canceled',
                details: 'details'
            },
            services: {
                base: 'services',
                domains: 'domains',
                domainDetails: 'domain-details',
                hosting: 'hosting',
                hostingDetails: 'hosting-details',
                servers: 'servers',
                serverDetails: 'server-details',
                ssl: 'ssl',
                sslDetails: 'ssl-details',
                marketing: 'marketing',
                marketingDetails: 'marketing-details',
                helpDesk: 'help-desk',
                helpDeskDetails: 'help-desk-details',
                emails: 'emails',
                emailDetails: 'email-details',
                seo: 'seo',
                seoDetails: 'seo-details',
                smartWebsites: 'smart-websites',
                smartWebsiteDetails: 'smart-website-details',
            },
            invoices: {
                base: 'invoices',
                all: 'all',
                expired: 'expired',
                paid: 'paid',
                toPay: 'to-pay'
            }
        },
        admin: {
            base: 'admin',
            projects: {
                base: 'projects',
                active: 'list',
                inactive: 'archive',
                details: 'details'
            },
            invoices: {
                base: 'invoices',
                active: 'active',
                inactive: 'archive',
                details: 'details',
            },
            services: {
                base: 'services',
                inactive: 'archive',
                domains: 'domains',
                domainDetails: 'domain-details',
                hosting: 'hosting',
                hostingDetails: 'hosting-details',
                servers: 'servers',
                serverDetails: 'server-details',
                ssl: 'ssl',
                sslDetails: 'ssl-details',
                marketing: 'marketing',
                marketingDetails: 'marketing-details',
                helpDesk: 'help-desk',
                helpDeskDetails: 'help-desk-details',
                seo: 'seo',
                seoDetails: 'seo-details',
                emails: 'emails',
                emailDetails: 'email-details',
                websites: 'websites',
                websiteDetails: 'website-details',
            },
            users: {
                base: 'users',
                active: 'active',
                ban: 'ban',
                details: 'details',
                ipBan: 'ip-ban',
                deleted: 'deleted'
            }
        }
    }
}
