import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginStatusService {
    private isLoggedInSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

    public setLoginStatus(isLoggedIn: boolean): void {
        this.isLoggedInSubject.next(isLoggedIn);
    }

    public getLoggedInStatus(): Observable<boolean> {
        return this.isLoggedInSubject.asObservable().pipe(
            filter((value: boolean | null) => {
                return value === true || value === false;
            }),
            first()
        );
    }
}
