import { Injectable } from '@angular/core';
import { v7 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class UuidService {
    public generate(): string {
        return v7();
    }
}
