import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { User } from '../api/user';
import { OnFinish } from 'src/app/core/api/on-finish';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';
import { EmailUnique } from '../api/email-unique';
import { RecaptchaService } from 'src/app/ui/services/recaptcha.service';
import { LoggedUser } from '../api/logged-user';
import { ConfirmMail } from '../api/confirm-mail';
import { PasswordChange } from '../api/password-change';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactChange } from '../api/contact-change';
import { ApiFilterConfig } from 'src/app/core/api/api-filter-config';
import { ApiResponse } from 'src/app/core/api/api-response';
import { UserInfo } from 'src/app/admin/api/user-info';
import { FullUser } from 'src/app/admin/api/full-user';
import { RegisterByAdmin } from 'src/app/admin/api/register-by-admin';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Id } from 'src/app/core/api/id';
import { ConfirmDeleteUser } from '../api/confirm-delete-user';
import { LoginStatusService } from 'src/app/core/services/login-status.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly apiPath: string = 'users';

    private loggedUserSubject: BehaviorSubject<LoggedUser | null> = new BehaviorSubject<LoggedUser | null>(null);

    public constructor(
        private readonly apiService: ApiService,
        private readonly recaptchaService: RecaptchaService,
        private readonly notificationService: NotificationService,
        private readonly loginStatusService: LoginStatusService,
    ) { }

    public getLoggedUser(): BehaviorSubject<LoggedUser | null> {
        return this.loggedUserSubject
    }

    public clearLoggedUserCache(): void {
        this.loggedUserSubject.next(null);
    }

    public isLoggedUser(loggedUser: LoggedUser | null): loggedUser is LoggedUser {
        return loggedUser !== null;
    }

    public getIRI(uuid: string): string {
        return this.apiService.apiUrl + this.apiPath + '/' + uuid;
    }

    public getCollection(
        apiFilterConfig: ApiFilterConfig,
        onSuccess: OnFinish<ApiResponse<UserInfo>>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.loginStatusService.getLoggedInStatus().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.apiService.getCollection(
                    this.apiPath,
                    {},
                    apiFilterConfig,
                    onSuccess,
                    onError,
                )
            }
        });
    }

    public post(
        data: User,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.recaptchaService.prepareTokenForAction(
            'postUser',
            () => {
                this.apiService.post(
                    this.apiPath,
                    data,
                    onSuccess,
                    onError,
                );
            }
        );
    }

    public getCollectionActive(
        apiFilterConfig: ApiFilterConfig,
        params: Record<string, string>,
        onSuccess: OnFinish<ApiResponse<FullUser>>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.loginStatusService.getLoggedInStatus().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.apiService.getCollection(
                    this.apiPath + '/active',
                    params,
                    apiFilterConfig,
                    onSuccess,
                    onError,
                )
            }
        });
    }

    public postByAdmin(
        data: RegisterByAdmin,
        onSuccess: OnFinish<Id>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.post(
            this.apiPath + '/byAdmin',
            data,
            onSuccess,
            onError,
        );
    }

    public confirmDelete(
        data: ConfirmDeleteUser,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        return this.apiService.delete(
            this.apiPath + '/confirmDelete',
            {
                email: data.email,
                token: data.token
            },
            onSuccess,
            onError
        )
    }

    public confirmEmail(
        data: ConfirmMail,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.patch(
            this.apiPath + '/confirmEmail',
            {
                email: data.email,
                emailConfirmationLink: data.token
            },
            onSuccess,
            onError,
        );
    }

    public getCollectionEmployee(
        apiFilterConfig: ApiFilterConfig,
        onSuccess: OnFinish<ApiResponse<FullUser>>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.getCollection(
            this.apiPath + '/employees',
            {},
            apiFilterConfig,
            onSuccess,
            onError,
        )
    }

    public getCollectionInactive(
        apiFilterConfig: ApiFilterConfig,
        params: Record<string, string>,
        onSuccess: OnFinish<ApiResponse<FullUser>>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.loginStatusService.getLoggedInStatus().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.apiService.getCollection(
                    this.apiPath + '/inactive',
                    params,
                    apiFilterConfig,
                    onSuccess,
                    onError,
                )
            }
        });
    }

    public checkEmail(email: string): Observable<EmailUnique> {
        return new Observable<EmailUnique>((observer) => {
            this.recaptchaService.prepareTokenForAction('isEmailUnique', () => {
                this.apiService.prepareGet(
                    this.apiPath + '/static/isEmailUnique',
                    { email }
                ).subscribe(
                    (response: any) => {
                        const emailUnique: EmailUnique = response as EmailUnique;

                        observer.next(emailUnique);
                        observer.complete();
                    },
                    (error: HttpErrorResponse) => {
                        observer.error(error);
                    }
                );
            });
        });
    }

    public refreshLoggedUserCache(): void {
        this.apiService.get(
            this.apiPath + '/static/loggedUser',
            {},
            (loggedUser: LoggedUser) => {
                this.loggedUserSubject.next(loggedUser);
            },
            () => {
                this.loggedUserSubject.next(null);
            }
        );
    }

    public getByUuid(
        uuid: string,
        onSuccess: OnFinish<FullUser>,
        onError: OnFinish<HttpErrorResponse>,
    ): void {
        this.loginStatusService.getLoggedInStatus().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                return this.apiService.get(
                    this.apiPath + '/' + uuid,
                    {},
                    onSuccess,
                    onError
                )
            }
        });
    }


    public delete(
        uuid: string,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        return this.apiService.delete(
            this.apiPath + '/' + uuid,
            {},
            onSuccess,
            onError
        )
    }

    public changeContact(
        uuid: string,
        data: ContactChange,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.patch(
            this.apiPath + '/' + uuid,
            data,
            onSuccess,
            onError,
        );
    }

    public deleteAuth(
        uuid: string,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        return this.apiService.delete(
            this.apiPath + '/' + uuid + '/auths',
            {},
            onSuccess,
            onError
        )
    }

    public changePassword(
        uuid: string,
        data: PasswordChange,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.patch(
            this.apiPath + '/' + uuid + '/changePassword',
            data,
            onSuccess,
            onError,
        );
    }

    public getInvoices(
        uuid: string,
        onSuccess: OnFinish<User>,
        onError: OnFinish<HttpErrorResponse>,
    ): void {
        return this.apiService.get(
            this.apiPath + '/' + uuid + '/invoices',
            {},
            onSuccess,
            onError
        )
    }

    public refreshConfirmationEmail(
        email: string,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        return this.apiService.patch(
            this.apiPath + '/refreshConfirmationEmail',
            { email },
            onSuccess,
            onError
        )
    }

    public getUserNotificationBlockades(
        uuid: string,
        onSuccess: OnFinish<FullUser>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.loginStatusService.getLoggedInStatus().subscribe((isLoggedIn: boolean) => {
            if (isLoggedIn) {
                this.apiService.get(
                    this.apiPath + '/' + uuid + '/userNotificationBlockades',
                    {},
                    onSuccess,
                    onError,
                )
            }
        });
    }

    public requestDelete(): void {
        this.getLoggedUser().pipe(
            filter(this.isLoggedUser),
            first()
        ).subscribe((loggedUser: LoggedUser | null) => {
            if (loggedUser) {
                return this.apiService.patch(
                    this.apiPath + '/' + loggedUser.uuid + '/requestDelete',
                    {},
                    () => {
                        this.notificationService.addWarningNotification($localize`Zgłoszenie usunięcia konta zostało wysłane na twój adres e-mail`, false);
                    },
                    () => {
                        this.notificationService.addServerError();
                    }
                )
            }
        });
    }

    public getUuidFromIRI(iri: string): string {
        return this.apiService.getUuidFromIRI(iri);
    }
}
