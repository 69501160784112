import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CookieLocalstorageRemoverService } from 'src/app/cookie/services/cookie-localstorage-remover.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from 'src/app/ui/components/button/button.component';
import { CookiePreference } from '../../api/cookie-preference';
import { cookiePreferenceAllDisabled } from '../../models/cookie-preference-all-disabled';
import { cookiePreferenceKey } from '../../models/cookie-preference-key';
import { LocalStorageService } from '../../services/local-storage.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';

@Component({
    standalone: true,
    selector: 'ui-cookie-popup-details',
    templateUrl: './cookie-popup-details.component.html',
    styleUrls: ['./cookie-popup-details.component.scss'],
    imports: [
        MatSlideToggleModule,
        FormsModule,
        MatDialogModule,
        ButtonComponent
    ],
    animations: [
        trigger('toggleHeight', [
            state('inactive', style({
                height: '0',
                overflow: 'hidden'
            })),
            state('active', style({
                height: '*'
            })),
            transition('inactive <=> active', [
                animate('120ms 32ms linear')
            ])
        ])
    ]
})
export class CookiePopupDetailsComponent {
    public readonly cookiePreferenceKey: string = cookiePreferenceKey;

    public requiredToggle: boolean = true;
    public functionalToggle: boolean = false;
    public performanceToggle: boolean = false;
    public adsToggle: boolean = false;

    public constructor(
        public readonly dialogRef: MatDialogRef<CookiePopupDetailsComponent>,
        private readonly cookieLocalstorageRemoverService: CookieLocalstorageRemoverService,
        private readonly localStorageService: LocalStorageService,
        private readonly googleAnalyticsService: GoogleAnalyticsService
    ) { }

    public onlyRequiredSubmit(): void {
        this.localStorageService.setPreference(cookiePreferenceAllDisabled);
        this.cookieLocalstorageRemoverService.removeFunctional();
        this.cookieLocalstorageRemoverService.removePerformance();
        this.cookieLocalstorageRemoverService.removeAds();
        this.dialogRef.close(true);
    }

    public selectedSubmit(): void {
        const preference: CookiePreference = this.localStorageService.getPreference();

        if (!this.functionalToggle) {
            this.cookieLocalstorageRemoverService.removeFunctional();
            preference.functional = 'disabled';
        } else {
            preference.functional = 'enabled';
        }

        if (!this.performanceToggle) {
            this.cookieLocalstorageRemoverService.removePerformance();
            preference.performance = 'disabled';
        } else {
            preference.performance = 'enabled';
        }

        if (!this.adsToggle) {
            this.cookieLocalstorageRemoverService.removeAds();
            preference.ads = 'disabled';
        } else {
            this.googleAnalyticsService.analyticsEnable(true);
            preference.ads = 'enabled';
        }

        this.localStorageService.setPreference(preference);
        this.dialogRef.close(true);
    }

    public allSubmit(): void {
        this.cookieLocalstorageRemoverService.removeAllCategoriesKeys();
        this.dialogRef.close(true);
    }

    public toggleDetails(element: HTMLElement, button: HTMLElement): void {
        element.classList.toggle('active');
        button.textContent = element.classList.contains('active') ? $localize`Ukryj szczegóły` : $localize`Pokaż szczegóły`;
    }
}
