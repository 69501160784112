import { Component, HostListener, AfterViewInit, RendererFactory2, Renderer2 } from '@angular/core';
import { ThemeService } from './themes/services/theme.service';
import { WindowService } from './core/services/window.service';
import { WindowExtension } from './core/api/window-extension';
import { RouterOutlet } from '@angular/router';
import { WindowScrollService } from './core/services/window-scroll.service';
import { WindowResizeService } from './core/services/window-resize.service';
import { VisibilityService } from './core/services/visibility.service';
import { PageLoaderComponent } from './core/components/page-loader/page-loader.component';
import { NotificationComponent } from './core/components/notification/notification.component';
import { CookiePopupComponent } from './cookie/components/cookie-popup/cookie-popup.component';
import { LoginService } from './user/services/login.service';
import { EncouragementWindowComponent } from './ui/components/encouragement-window/encouragement-window.component';
import { globals } from './globals';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { LocalStorageService } from './cookie/services/local-storage.service';
import { PlatformService } from './core/services/platform.service';

@Component({
    selector: '#app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
        RouterOutlet,
        PageLoaderComponent,
        NotificationComponent,
        CookiePopupComponent,
        EncouragementWindowComponent
    ],
})
export class AppComponent implements AfterViewInit {
    private readonly loadedClass: string = 'loaded';

    private windowExtension: WindowExtension;
    private document: Document;

    public constructor(
        private readonly windowService: WindowService,
        private readonly themeService: ThemeService,
        private readonly loginService: LoginService,
        private readonly windowScrollService: WindowScrollService,
        private readonly windowResizeService: WindowResizeService,
        private readonly visibilityService: VisibilityService,
        private readonly localStorageService: LocalStorageService,
        private readonly googleAnalyticsService: GoogleAnalyticsService,
        private readonly platformService: PlatformService,
        private readonly rendererFactory: RendererFactory2,
    ) {
        globals.localStorageService = this.localStorageService;

        this.windowExtension = this.windowService.getWindowExtension();
        this.document = this.windowService.getDocument();

        if (this.windowExtension.loadingInterval) {
            window.clearInterval(this.windowExtension.loadingInterval);
        }

        this.themeService.init();
        this.loginService.init();
    }

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformBrowser()) {
            const renderer: Renderer2 | null = this.rendererFactory.createRenderer(null, null);

            renderer.addClass(this.document.body, this.loadedClass);
            this.googleAnalyticsService.init();
        }
    }

    @HostListener('window:visibilitychange', ['$event'])
    public onWindowVisible(): void {
        this.visibilityService.setWindowVisible(!this.document.hidden);
    }

    @HostListener('window:scroll', ['$event'])
    public onScroll(): void {
        this.windowScrollService.setScroll(this.windowExtension.scrollY);
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.windowScrollService.setScroll(this.windowExtension.scrollY);
        this.windowResizeService.setSize({
            width: this.windowExtension.innerWidth,
            height: this.windowExtension.innerHeight
        })
    }
}
