import { MatPaginatorIntl } from '@angular/material/paginator';

export class CustomPaginatorIntl {
    static rangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return '0' + $localize` z ` + length;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;

        return (startIndex + 1) + ' - ' + endIndex + $localize` z ` + length;
    }

    static getPaginatorIntl(): MatPaginatorIntl {
        const paginatorIntl = new MatPaginatorIntl();

        paginatorIntl.firstPageLabel = $localize`Pierwsza strona`;
        paginatorIntl.previousPageLabel = $localize`Wcześniejsza strona`;
        paginatorIntl.nextPageLabel = $localize`Następna strona`;
        paginatorIntl.lastPageLabel = $localize`Ostatnia strona`;
        paginatorIntl.getRangeLabel = CustomPaginatorIntl.rangeLabel;

        return paginatorIntl;
    }
}
