import { Injectable } from '@angular/core';
import { Role } from 'src/app/user/api/role';
import { globals } from 'src/app/globals';
import { LocalStorageService } from 'src/app/cookie/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {
    public static readonly adminRole: string = 'ADMIN';
    public static readonly employeeRole: string = 'EMPLOYEE';

    private static readonly tokenKey: string = 'token';
    private static readonly rolesKey: string = 'roles';

    private static roles: Role[] | null = null;

    public constructor(
        private readonly localStorageService: LocalStorageService,
    ) { }

    public static isLoggedIn(): boolean {
        return globals.localStorageService?.getItem(AuthorizationService.tokenKey) !== null;
    }

    public static hasRole(role: string): boolean {
        const roles: Role[] = AuthorizationService.getRoles();

        return AuthorizationService.isLoggedIn() && roles.filter(e => e.code === role).length > 0;
    }

    private static getRoles(): Role[] {
        if (AuthorizationService.roles === null) {
            AuthorizationService.roles = JSON.parse(
                globals.localStorageService?.getItem(AuthorizationService.rolesKey) ?? '[]'
            ) as Role[];
        }

        return AuthorizationService.roles;
    }

    public isLoggedIn(): boolean {
        return AuthorizationService.isLoggedIn();
    }

    public getAuthorization(): string {
        return this.localStorageService.getItem(AuthorizationService.tokenKey) ?? '';
    }

    public setAuthorization(authorization: string): void {
        this.localStorageService.setItem(AuthorizationService.tokenKey, authorization);
    }

    public removeAuthorization(): void {
        this.localStorageService.removeItem(AuthorizationService.tokenKey);
    }

    public getRoles(): Role[] {
        return AuthorizationService.getRoles();
    }

    public setRoles(roles: Role[]): void {
        AuthorizationService.roles = roles;
        this.localStorageService.setItem(AuthorizationService.rolesKey, JSON.stringify(roles));
    }

    public removeRoles(): void {
        AuthorizationService.roles = null;
        this.localStorageService.removeItem(AuthorizationService.rolesKey);
    }

    public isAdmin(): boolean {
        return this.getRoles().filter(e => e.code === AuthorizationService.adminRole).length > 0;
    }

    public isEmployee(): boolean {
        return this.getRoles().filter(e => e.code === AuthorizationService.employeeRole).length > 0;
    }
}
