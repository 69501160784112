import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: '.ui-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @Input() public isLoading: boolean = false;
    @Input() public isLeft: boolean = false;
    @Input() public loadingColor: string = '';
}
