import { Injectable } from '@angular/core';
import { AuthorizationService } from '../../core/services/authorization.service';
import { WindowService } from 'src/app/core/services/window.service';
import { CookieLocalstorageHelperService } from './cookie-localstorage-helper.service';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    private document: Document;

    public constructor(
        private readonly authorizationService: AuthorizationService,
        private readonly windowService: WindowService,
        private readonly cookieLocalstorageHelperService: CookieLocalstorageHelperService
    ) {
        this.document = this.windowService.getDocument();
    }

    public setAuthorizationCookie(): void {
        this.set('auth', this.authorizationService.getAuthorization(), 1);
    }

    public deleteAuthorizationCookie(): void {
        this.delete('auth');
    }

    public set(name: string, value: string, days: number): void {
        if (this.cookieLocalstorageHelperService.shouldBeRemoved(name, 'cookie')) {
            return;
        }

        const date: Date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        this.document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    }

    public get(name: string): string {
        if (!this.document.cookie) {
            return '';
        }

        let ca: string[] = this.document.cookie.split(';'),
            caLen: number = ca.length,
            cookieName: string = `${name}=`,
            c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');

            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }

        return '';
    }

    public delete(name: string): void {
        const date: Date = new Date();

        date.setTime(date.getTime() - (24 * 60 * 60 * 1000));
        this.document.cookie = name + '=;expires=' + date.toUTCString() + ';path=/';
    }

    public getAll(): string[] {
        return this.document.cookie.split(';');
    }
}
