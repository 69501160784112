import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ContactMessage } from '../api/contact-message';
import { HttpErrorResponse } from '@angular/common/http';
import { OnFinish } from 'src/app/core/api/on-finish';
import { RecaptchaService } from 'src/app/ui/services/recaptcha.service';
import { PhoneContact } from '../api/phone-contact';
import { PromotionMessage } from '../api/promotion-message';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    private readonly apiPath: string = 'contactMessages';

    public constructor(
        private readonly apiService: ApiService,
        private readonly recaptchaService: RecaptchaService,
    ) { }

    public getIRI(id: number): string {
        return this.apiService.apiUrl + this.apiPath + '/' + id;
    }

    public sendMessage(
        data: ContactMessage,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.recaptchaService.prepareTokenForAction(
            'postContactMessage',
            () => {
                this.apiService.post(
                    this.apiPath,
                    data,
                    onSuccess,
                    onError,
                    false
                );
            }
        );
    }

    public sendPhoneContact(
        data: PhoneContact,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.recaptchaService.prepareTokenForAction(
            'postContactMessageOnlyPhone',
            () => {
                this.apiService.post(
                    this.apiPath + '/onlyPhone',
                    data,
                    onSuccess,
                    onError,
                    false
                );
            }
        );
    }

    public sendPromotionMessage(
        data: PromotionMessage,
        onSuccess: OnFinish<void>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.recaptchaService.prepareTokenForAction(
            'postContactPromotionMessage',
            () => {
                this.apiService.post(
                    this.apiPath + '/promotionMessage',
                    data,
                    onSuccess,
                    onError,
                    false
                );
            }
        );
    }
}
