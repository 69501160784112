import { Injectable } from '@angular/core';
import { of, delay, tap, first } from 'rxjs';
import { NotificationType } from '../api/notification-type';
import { Notification } from '../api/notification';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {
    private readonly autoDismissDelay = 5000;

    private notifications: Notification[] = [];

    public addSuccessNotification(message: string, isAutoDismiss: boolean = true): void {
        this.addNotification(NotificationType.success, message, isAutoDismiss);
    }

    public addWarningNotification(message: string, isAutoDismiss: boolean = true): void {
        this.addNotification(NotificationType.warning, message, isAutoDismiss);
    }

    public addErrorNotification(message: string, isAutoDismiss: boolean = true): void {
        this.addNotification(NotificationType.error, message, isAutoDismiss);
    }

    public addInfoNotification(message: string, isAutoDismiss: boolean = true): void {
        this.addNotification(NotificationType.info, message, isAutoDismiss);
    }

    public getNotifications(): Notification[] {
        return this.notifications;
    }

    public dismissNotification(notification: Notification): void {
        notification.isDismissed = true;
    }

    public addServerError(): void {
        this.addErrorNotification($localize`Wystąpił błąd serwera! Spróbuj ponownie później.`);
    }

    public addWarningEmailNotConfirmed(): void {
        this.addWarningNotification($localize`Nie potwierdzono adresu email!`);
    }

    private addNotification(type: NotificationType, message: string, isAutoDismiss: boolean): void {
        const notification: Notification = {
            type: type,
            message: message,
            isAutoDismiss: isAutoDismiss,
            isDismissed: false
        }

        this.notifications.push(notification);

        if (notification.isAutoDismiss) {
            of(notification).pipe(
                delay(this.autoDismissDelay),
                tap(this.dismissNotification.bind(this)),
                first()
            ).subscribe();
        }
    }
}
