import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateFormatterService {
    public constructor(
        @Inject(LOCALE_ID) private readonly localeId: string,
    ) { }

    public format(date: Date | string): string {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        return new Intl.DateTimeFormat(this.localeId).format(date);
    }

    public getFormattedDate(date: Date | null | undefined | string): string | null | undefined {
        if (!date) {
            return date;
        }

        if (date instanceof Date) {
            date.setHours(0, 0, 0, 0);
        }

        return this.format(date);
    }
}
