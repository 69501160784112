import { Injectable } from '@angular/core';
import { CookieLocalstorageHelperService } from 'src/app/cookie/services/cookie-localstorage-helper.service';
import { CookiePreference } from 'src/app/cookie/api/cookie-preference';
import { cookiePreferenceAllDisabled } from 'src/app/cookie/models/cookie-preference-all-disabled';
import { cookiePreferenceKey } from 'src/app/cookie/models/cookie-preference-key';
import { PlatformService } from 'src/app/core/services/platform.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private readonly preferenceKey: string = cookiePreferenceKey;
    private readonly cookiePreferenceAllDisabled: CookiePreference = cookiePreferenceAllDisabled;

    public constructor(
        private readonly platformService: PlatformService,
        private readonly cookieLocalstorageHelperService: CookieLocalstorageHelperService
    ) { }

    public setItem(key: string, value: string): void {
        if (this.cookieLocalstorageHelperService.shouldBeRemoved(key, 'localstorage')) {
            return;
        }

        if (this.platformService.isPlatformBrowser()) {
            localStorage.setItem(key, value);
        }
    }

    public getItem(key: string): string | null {
        if (this.platformService.isPlatformBrowser()) {
            return localStorage.getItem(key);
        }

        return null;
    }

    public removeItem(key: string): void {
        if (this.platformService.isPlatformBrowser()) {
            localStorage.removeItem(key);
        }
    }

    public setPreference(preference: CookiePreference): void {
        this.setItem(this.preferenceKey, JSON.stringify(preference));
    }

    public getPreference(): CookiePreference {
        const preference: string | null = this.getItem(this.preferenceKey);

        return preference ? JSON.parse(preference) : this.cookiePreferenceAllDisabled;
    }
}
