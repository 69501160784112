@if (isRequired) {
<div class="overlay" (click)="close(overlay, container)" #overlay></div>
<div class="container" [class.active]="isActive" #container>
    <img alt="Darmowa wycena" i18n-alt loading="eager" src="/data/images/quotation-popup.webp">
    <div class="wrapper custom-scrollbar">
        <div class="content" [class.submitted]="isSubmitted">
            <h2 i18n>Darmowa wycena</h2>
            <h3>
                <span i18n>Skorzystaj z darmowej wyceny</span><br />
                <span i18n>Twojego projektu!</span>
            </h3>
            <p>
                <span i18n>Zostaw nam swój e-mail lub numer telefonu,</span><br />
                <span i18n>a na pewno się z Tobą skontaktujemy!</span>
            </p>
            <div class="form-container">
                <form>
                    <div class="ui-subform-phone-or-mail" [isOneColor]="true"></div>
                    <div class="button-container ui-loader" [isLoading]="isLoading" loadingColor="white">
                        <button aria-label="Wyceń" class="ui-button success-solid" (click)="submit()" i18n
                            i18n-aria-label type="submit">Wyceń</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="content" [class.submitted]="!isSubmitted">
            <h2 i18n>Dziękujemy!</h2>
            <h3 i18n>Twoje zgłoszenie zostało wysłane.</h3>
            <p i18n>Skontaktujemy się z Tobą tak szybko jak to możliwe!</p>
        </div>
    </div>
    <mat-icon (click)="close(overlay, container)">close</mat-icon>
</div>
}
