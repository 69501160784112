import { Routes } from '@angular/router';
import { PathService } from '../../locale/services/path.service';
import { AclMatcherService } from '../services/acl-matcher.service';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('../../front/views/layout/layout.component').then(m => m.LayoutComponent),
        children: [
            {
                path: '',
                loadComponent: () => import('../../front/views/home/home.component').then(m => m.HomeComponent),
                data: {
                    path: ''
                }
            },
            {
                path: PathService.getRouterPath('offer'),
                loadComponent: () => import('../../front/views/offer/offer.component').then(m => m.OfferComponent),
                data: {
                    path: 'offer'
                }
            },
            {
                path: PathService.getRouterPath('about'),
                loadComponent: () => import('../../front/views/about/about.component').then(m => m.AboutComponent),
                data: {
                    path: 'about'
                }
            },
            {
                path: PathService.getRouterPath('career'),
                loadComponent: () => import('../../front/views/career/career.component').then(m => m.CareerComponent),
                data: {
                    path: 'career'
                }
            },
            {
                path: PathService.getRouterPath('contact'),
                loadComponent: () => import('../../front/views/contact/contact.component').then(m => m.ContactComponent),
                data: {
                    path: 'contact'
                }
            },
            {
                path: PathService.getRouterPath('login'),
                loadComponent: () => import('../../front/views/login/login.component').then(m => m.LoginComponent),
                canMatch: [AclMatcherService.isLoggedOut],
                data: {
                    path: 'login'
                }
            },
            {
                path: PathService.getRouterPath('register'),
                loadComponent: () => import('../../front/views/register/register.component').then(m => m.RegisterComponent),
                canMatch: [AclMatcherService.isLoggedOut],
                data: {
                    path: 'register'
                }
            },
            {
                path: PathService.getRouterPath('remindPassword'),
                loadComponent: () => import('../../front/views/password-remind/password-remind.component').then(m => m.PasswordRemindComponent),
                canMatch: [AclMatcherService.isLoggedOut],
                data: {
                    path: 'remindPassword'
                }
            },
            {
                path: PathService.getRouterPath('setPassword'),
                loadComponent: () => import('../../front/views/password-set/password-set.component').then(m => m.PasswordSetComponent),
                canMatch: [AclMatcherService.isLoggedOut],
                data: {
                    path: 'setPassword'
                }
            },
            {
                path: PathService.getRouterPath('confirmEmail'),
                loadComponent: () => import('../../front/views/confirm-email/confirm-email.component')
                    .then(m => m.ConfirmEmailComponent),
                data: {
                    path: 'confirmEmail'
                }
            },
            {
                path: PathService.getRouterPath('regulations'),
                loadComponent: () => import('../../front/views/regulations/regulations.component')
                    .then(m => m.RegulationsComponent),
                data: {
                    path: 'regulations'
                }
            },
            {
                path: PathService.getRouterPath('privacyPolicy'),
                loadComponent: () => import('../../front/views/privacy-policy/privacy-policy.component')
                    .then(m => m.PrivacyPolicyComponent),
                data: {
                    path: 'privacyPolicy'
                }
            },
            {
                path: PathService.getRouterPath('gdpr'),
                loadComponent: () => import('../../front/views/gdpr/gdpr.component')
                    .then(m => m.GdprComponent),
                data: {
                    path: 'gdpr'
                }
            },
            {
                path: PathService.getRouterPath('confirmAccountDeletion'),
                loadComponent: () => import('../../front/views/confirm-account-deletion/confirm-account-deletion.component')
                    .then(m => m.ConfirmAccountDeletionComponent),
                data: {
                    path: 'confirmAccountDeletion'
                }
            },
            {
                path: PathService.getRouterPath('changeEmail'),
                loadComponent: () => import('../../front/views/change-email/change-email.component')
                    .then(m => m.ChangeEmailComponent),
                data: {
                    path: 'changeEmail'
                }
            },
        ]
    },
    {
        path: PathService.getRouterPath('customer'),
        canMatch: [AclMatcherService.isLoggedIn],
        loadComponent: () => import('../../user/views/panel/panel.component').then(m => m.PanelComponent),
        loadChildren: () => import('../../client/models/routes').then(m => m.routes),
    },
    {
        path: PathService.getRouterPath('admin'),
        loadComponent: () => import('../../user/views/panel/panel.component').then(m => m.PanelComponent),
        canMatch: [AclMatcherService.isAdmin],
        loadChildren: () => import('../../admin/models/routes').then(m => m.routes),
    },
    {
        path: PathService.getRouterPath('login'),
        redirectTo: PathService.getRouterPath('customer')
    },
    {
        path: PathService.getRouterPath('register'),
        redirectTo: PathService.getRouterPath('customer')
    },
    {
        path: PathService.getRouterPath('remindPassword'),
        redirectTo: PathService.getRouterPath('customer')
    },
    {
        path: PathService.getRouterPath('setPassword'),
        redirectTo: PathService.getRouterPath('customer')
    },
    {
        path: PathService.getRouterPath('customer'),
        redirectTo: PathService.getRouterPath('login')
    },
    {
        path: '**',
        redirectTo: ''
    },
];
