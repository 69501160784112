import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    standalone: true,
    name: 'safeHtmlPipe'
})
export class SafeHtmlPipe implements PipeTransform {
    public constructor(
        private readonly domSanitizer: DomSanitizer
    ) { }

    public transform(htmlCode: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(htmlCode);
    }
}
