<div class="container">
    <h2 mat-dialog-title i18n>Szczegóły plików Cookies</h2>
    <div mat-dialog-content class="content custom-scrollbar">
        <div class="required">
            <div class="title">
                <h3 i18n>Niezbędne</h3>
                <mat-slide-toggle color="primary" [(ngModel)]="requiredToggle" name="requiredToggle"
                    [disabled]="true"></mat-slide-toggle>
            </div>
            <p i18n>Te pliki Cookies są wymagane dla poprawnego funkcjonowania niniejszej strony internetowej i nie mogą
                być wyłączone w naszym systemie.</p>
            <ul [@toggleHeight]="requiredDetails.className.includes('active') ? 'active' : 'inactive'" #requiredDetails>
                <h4 class="subtitle">Cookie:</h4>
                <li>
                    <strong>auth</strong>
                    <span i18n>Ten plik Cookie służy jako klucz do utrzymywania sesji
                        użytkownika przy kolejnych żądaniach, identyfikując użytkownika bez konieczności ponownego
                        uwierzytelniania. Jest przesyłany między klientem a&nbsp;serwerem.</span>
                </li>
                <li>
                    <strong>lang</strong>
                    <span i18n>Ten plik Cookie jest potrzebny do poprawnego rozpoznawania języka podczas pierwszej
                        wizyty w witrynie. Jest wykorzystywany po stronie serwera.</span>
                </li>
                <h4 class="subtitle">Localstorage:</h4>
                <li>
                    <strong>cookie-popup</strong>
                    <span i18n>Dane, w których zapisane są preferencje dotyczące plików Cookies dla tej strony
                        internetowej. Możesz je łatwo zmienić lub wycofać swoją zgodę.</span>
                </li>
                <li>
                    <strong>{{cookiePreferenceKey}}</strong>
                    <span i18n>Dane, w których zapisane są preferencje dotyczące funkcjonalnych, wydajnościowych oraz
                        reklamowych plików Cookies dla tej strony. Możesz je łatwo zmienić lub wycofać swoją
                        zgodę.</span>
                </li>
                <li>
                    <strong>_grecaptcha</strong>
                    <span i18n>Dane potrzebne do poprawnego działania systemu Google
                        ReCaptcha v3, który zapewnia niniejszej stronie zabezpieczenie antyspamowe.</span>
                </li>
                <li>
                    <strong>roles</strong>
                    <span i18n>Dane potrzebne do poprawnego działania systemu logowania i&nbsp;rejestracji.</span>
                </li>
                <li>
                    <strong>token</strong>
                    <span i18n>Bezpieczny i długi ciąg znaków służący do uwierzytelniania
                        użytkowników.</span>
                </li>
                <h4 class="subtitle">Zewnętrzne:</h4>
                <li>
                    <strong>Google Recaptcha</strong>
                    <span i18n>
                        Pliki Cookies używane do ochrony stron internetowych przed spamem i nadużyciami. Pomaga
                        rozróżnić działania ludzi od automatycznych botów, analizując zachowanie użytkownika podczas
                        interakcji ze stroną. Cookie to gromadzi anonimowe dane, takie jak czas spędzony na stronie czy
                        ruchy myszką, aby ocenić, czy użytkownik jest człowiekiem, bez ingerencji w treści
                        witryny.</span>
                </li>
                <li>
                    <strong>Google Maps</strong>
                    <span i18n>
                        Te pliki Cookies pochodzą od Google Maps i są używane do prawidłowego wyświetlania map oraz
                        zapewnienia ich pełnej funkcjonalności. Umożliwiają m.in. uwierzytelnianie użytkownika,
                        zapamiętywanie jego preferencji oraz zwiększają bezpieczeństwo korzystania z map.</span>
                </li>
            </ul>
            <span aria-label="Pokaż szczegóły" i18n-aria-label class="button"
                (click)="toggleDetails(requiredDetails, requiredDetailsButton)" #requiredDetailsButton i18n>Pokaż
                szczegóły</span>
        </div>
        <div class="performance">
            <div class="title">
                <h3 i18n>Wydajnościowe</h3>
                <mat-slide-toggle color="primary" [(ngModel)]="performanceToggle" name="performance"></mat-slide-toggle>
            </div>
            <p i18n>Używamy tych plików Cookies do poprawy wydajności niniejszej strony internetowej.</p>
            <ul [@toggleHeight]="performanceDetails.className.includes('active') ? 'active' : 'inactive'"
                #performanceDetails>
                <h4 class="subtitle">LocalStorage:</h4>
                <li>
                    <strong>cache.*</strong>
                    <span i18n>Zapisują się w nich dane pobierane z serwerów, które bez
                        zapisania musiałyby być pobierane każdorazowo od nowa. Dzięki nim, treści w obrębie strony
                        ładują się szybciej, a połączenie internetowe jest mniej obciążone, ponieważ nie wszystkie
                        elementy muszą wczytywać się za każdym razem od nowa.</span>
                </li>
            </ul>
            <span aria-label="Pokaż szczegóły" i18n-aria-label class="button"
                (click)="toggleDetails(performanceDetails, performanceDetailsButton)" #performanceDetailsButton
                i18n>Pokaż szczegóły</span>
        </div>
        <div class="functional">
            <div class="title">
                <h3 i18n>Funkcjonalne</h3>
                <mat-slide-toggle color="primary" [(ngModel)]="functionalToggle" name="functional"></mat-slide-toggle>
            </div>
            <p i18n>Używamy tych plików Cookies w celu zwiększenia funkcjonalności i&nbsp;umożliwienia personalizacji
                systemu.</p>
            <ul [@toggleHeight]="functionalDetails.className.includes('active') ? 'active' : 'inactive'"
                #functionalDetails>
                <h4 class="subtitle">Cookie:</h4>
                <li>
                    <strong>theme</strong>
                    <span i18n>Służy do zapisywania informacji o wybranym przez użytkownika
                        motywie kolorystycznym strony.</span>
                </li>
                <h4 class="subtitle">LocalStorage:</h4>
                <li>
                    <strong>encouragement-popup</strong>
                    <span i18n>Dane, w których zapisane są informacje dotyczące
                        stanu okna pop-up powitalnego na stronie.</span>
                </li>
                <li>
                    <strong>last-navbar-visited-page</strong>
                    <span i18n>Przechowuje informację o ostatnio otwartej pozycji menu w panelu klienta.
                        Stosowana jest do podświetlania odpowiednich pozycji menu.</span>
                </li>
            </ul>
            <span aria-label="Pokaż szczegóły" i18n-aria-label class="button"
                (click)="toggleDetails(functionalDetails, functionalDetailsButton)" #functionalDetailsButton i18n>Pokaż
                szczegóły</span>
        </div>
        <div class="ads">
            <div class="title">
                <h3 i18n>Reklama / Śledzenie</h3>
                <mat-slide-toggle color="primary" [(ngModel)]="adsToggle" name="ads"></mat-slide-toggle>
            </div>
            <p i18n>Te pliki Cookies są ustawiane przez naszych partnerów reklamowych za pośrednictwem naszej strony
                internetowej.</p>
            <ul [@toggleHeight]="adsDetails.className.includes('active') ? 'active' : 'inactive'" #adsDetails>
                <h4 class="subtitle">Zewnętrzne:</h4>
                <li>
                    <strong>Google tag manager</strong>
                    <span i18n>Te pliki Cookies pozwalają na zarządzanie tagami marketingowymi i analitycznymi na
                        stronie. Google Tag Manager umożliwia śledzenie zachowań użytkowników oraz optymalizację
                        kampanii reklamowych poprzez integrację różnych narzędzi śledzących. Pliki te mogą być
                        wykorzystywane do personalizacji treści reklamowych i analizy ruchu na stronie.</span>
                </li>
            </ul>
            <span aria-label="Pokaż szczegóły" i18n-aria-label class="button"
                (click)="toggleDetails(adsDetails, adsDetailsButton)" #adsDetailsButton i18n>Pokaż
                szczegóły</span>
        </div>
        <div mat-dialog-actions class="actions">
            <button aria-label="Odrzuć" i18n-aria-label class="ui-button error" type="button"
                (click)="onlyRequiredSubmit()" i18n>Odrzuć</button>
            <button aria-label="Zaakceptuj wybrane" i18n-aria-label class="ui-button warn" type="button"
                (click)="selectedSubmit()" i18n>Zaakceptuj
                wybrane</button>
            <button aria-label="Zaakceptuj wszystko" i18n-aria-label class="ui-button acceptance" type="button"
                (click)="allSubmit()" i18n>Zaakceptuj wszystko</button>
        </div>
    </div>
</div>
