import { Injectable } from '@angular/core';
import { Theme } from '../api/theme';
import { DarkTheme } from '../themes/dark/dark.theme';
import { LightTheme } from '../themes/light/light.theme';
import { WindowService } from 'src/app/core/services/window.service';
import { WindowExtension } from 'src/app/core/api/window-extension';
import { PlatformService } from 'src/app/core/services/platform.service';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'src/app/cookie/services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private readonly themePropertyKey: string = 'theme';

    public isDarkThemeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isDarkTheme());

    private windowExtension: WindowExtension;
    private lastTheme?: Theme;

    public constructor(
        private readonly darkTheme: DarkTheme,
        private readonly lightTheme: LightTheme,
        private readonly windowService: WindowService,
        private readonly platformService: PlatformService,
        private readonly cookieService: CookieService
    ) {
        this.windowExtension = this.windowService.getWindowExtension();
    }

    public init(): void {
        if (this.platformService.isPlatformBrowser()) {
            this.getTheme().apply();
        }
    }

    public changeTheme(): void {
        if (this.isLightTheme()) {
            this.cookieService.set(this.themePropertyKey, this.darkTheme.class, 365);
            this.lastTheme = this.darkTheme;
            this.lightTheme.remove();
            this.darkTheme.apply();
            this.isDarkThemeSubject.next(true);
        } else {
            this.cookieService.set(this.themePropertyKey, this.lightTheme.class, 365);
            this.lastTheme = this.lightTheme;
            this.darkTheme.remove();
            this.lightTheme.apply();
            this.isDarkThemeSubject.next(false);
        }
    }

    public getTheme(): Theme {
        const chosenTheme: string | undefined = this.cookieService.get(this.themePropertyKey) !== '' ? this.cookieService.get(this.themePropertyKey) : this.lastTheme?.class;

        if (chosenTheme === this.darkTheme.class) {
            return this.darkTheme;
        }

        if (chosenTheme === this.lightTheme.class) {
            return this.lightTheme;
        }

        if (this.windowExtension && this.windowExtension.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.cookieService.set(this.themePropertyKey, this.darkTheme.class, 365);
            this.isDarkThemeSubject.next(true);

            return this.darkTheme;
        }

        if (this.windowExtension) {
            this.cookieService.set(this.themePropertyKey, this.lightTheme.class, 365);
        }

        return this.lightTheme;
    }

    public isDarkTheme(): boolean {
        return this.getTheme().class === this.darkTheme.class;
    }

    public isLightTheme(): boolean {
        return this.getTheme().class === this.lightTheme.class;
    }
}
