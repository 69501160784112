import { Injectable } from '@angular/core';
import { WindowExtension } from '../api/window-extension';
import { getDocument, getWindow } from 'ssr-window';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    public getWindowExtension(): WindowExtension {
        return getWindow();
    }

    public getDocument(): Document {
        return getDocument();
    }
}
