import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: '.ui-subform-phone-or-mail',
    standalone: true,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule
    ],
    templateUrl: './phone-or-mail.component.html',
})
export class PhoneOrMailComponent {
    @Input() public isOneColor: boolean = true;
    public phoneOrMail: FormControl;

    public constructor() {
        this.phoneOrMail = new FormControl('', [
            Validators.required,
            Validators.pattern(/^(?:\+?\d{1,3})?\s?(?:\(?\d{2,4}\)?)?\s?\d{2,4}(?:[-\s]?\d{2,4}){1,3}$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,10}$/)
        ]);
    }

    public getError(): string | null {
        if (this.phoneOrMail.hasError('required')) {
            return $localize`Pole jest wymagane!`;
        }

        if (this.phoneOrMail.hasError('pattern')) {
            return $localize`Pole musi być numerem telefonu albo mailem!`;
        }

        return null;
    }

    public getValue(): string {
        return this.phoneOrMail.value;
    }

    public isValid(): boolean {
        return this.phoneOrMail.valid;
    }
}
