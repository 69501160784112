import { cookieLocalstorageItem } from '../api/cookie-localstorage-item';
import { cookiePreferenceKey } from './cookie-preference-key';

export const localstorageItems: cookieLocalstorageItem[] = [
    {
        group: 'required',
        items: [
            cookiePreferenceKey,
            'cookie-popup',
            'roles',
            'token',
            '_grecaptcha',
        ]
    },
    {
        group: 'functional',
        items: [
            'encouragement-popup',
            'last-navbar-visited-page'
        ]
    },
    {
        group: 'performance',
        items: [
            'cache.*'
        ]
    }
];
