import { Injectable } from '@angular/core';
import { OnFinish } from 'src/app/core/api/on-finish';
import { ApiService } from 'src/app/core/services/api.service';
import { Role } from '../api/role';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    private readonly apiPath: string = 'roles';

    public constructor(
        private readonly apiService: ApiService
    ) { }

    public getIRI(uuid: string): string {
        return this.apiService.apiUrl + this.apiPath + '/' + uuid;
    }

    public getCollectionForUser(
        onSuccess: OnFinish<Role[]>,
        onError: OnFinish<HttpErrorResponse>
    ): void {
        this.apiService.get(
            this.apiPath,
            {},
            onSuccess,
            onError
        );
    }
}
