import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Theme } from '../../api/theme';
import { WindowService } from 'src/app/core/services/window.service';

@Injectable({
    providedIn: 'root'
})
export class LightTheme implements Theme {
    private renderer: Renderer2

    public class: string = 'light-theme';

    private document: Document;

    public constructor(
        private readonly rendererFactory: RendererFactory2,
        private readonly windowService: WindowService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.document = this.windowService.getDocument();
    }

    public apply(): void {
        this.renderer.addClass(this.document.body, this.class);
    }

    public remove(): void {
        this.renderer.removeClass(this.document.body, this.class);
    }
}
