import { Injectable } from '@angular/core';
import { PathGroup } from '../api/path-group';
import { Paths } from '../models/paths';
import { environment } from 'src/environments/environment';
import { LocaleId } from '../api/locale-id';

@Injectable({
    providedIn: 'root'
})
export class PathService {
    private baseUrl: string = 'https://rokezzz.pl';

    public static getRouterPath(path: string): string {
        const pathParts: string[] = path.split('.');

        let resultUrl: string = '',
            paths: PathGroup | string = PathService.getPaths();

        for (let part of pathParts) {
            if (typeof paths === 'string' || paths[part] === undefined) {
                return '/'
            }

            resultUrl = this.getPartPath(paths[part]);
            paths = paths[part];
        }

        return resultUrl;
    }

    private static getPartPath(path: string | PathGroup): string {
        if (typeof path === 'string') {
            return path;
        }

        return path.base;
    }

    private static getPaths(): PathGroup {
        return Paths[environment.locale.id];
    }

    public constructor() {
        this.baseUrl += PathService.getPaths().base.slice(0, -1);
    }

    public getUrlForPath(path: string): string {
        const pathParts: string[] = path.split('.');

        let resultUrl: string = '',
            paths: PathGroup | string = PathService.getPaths();

        for (let part of pathParts) {
            if (typeof paths === 'string' || paths[part] === undefined) {
                return '/'
            }

            resultUrl += '/' + PathService.getPartPath(paths[part]);
            paths = paths[part];
        }

        return resultUrl;
    }

    public getCanonicalUrl(path: string): string {
        return this.baseUrl + this.getUrlForPath(path)
    }

    public getTranslatedPath(path: string, localeId: LocaleId): string {
        const pathParts: string[] = path.split('.');

        let paths: PathGroup | string = Paths[localeId.id],
            resultUrl: string = paths.base.slice(0, -1);

        for (let part of pathParts) {
            if (typeof paths === 'string' || paths[part] === undefined) {
                return resultUrl + '/';
            }

            resultUrl += '/' + PathService.getPartPath(paths[part]);
            paths = paths[part];
        }

        return resultUrl;
    }
}
