import { Injectable, LOCALE_ID, Inject, Renderer2, RendererFactory2, CSP_NONCE } from '@angular/core';
import { ThemeService } from 'src/app/themes/services/theme.service';
import { environment } from 'src/environments/environment';
import { OnFinish } from '../api/on-finish';
import { first, timer } from 'rxjs';
import { WindowService } from './window.service';
import { PlatformService } from './platform.service';
import { coreConfig } from '../models/core-config';
import { UserService } from 'src/app/user/services/user.service';
import { WindowExtension } from '../api/window-extension';
import { LocalStorageService } from 'src/app/cookie/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    private readonly googleAnalyticsApiKey: string = environment.googleAnalyticsApiKey;

    private googleAnalyticsLoaded: boolean = false;
    private googleAnalyticsLoading: boolean = false;
    private renderer: Renderer2;
    private document: Document;
    private window: WindowExtension;

    public constructor(
        @Inject(LOCALE_ID) private readonly localeId: string,
        @Inject(CSP_NONCE) private readonly cspNonce: string,
        private readonly themeService: ThemeService,
        private readonly userService: UserService,
        private readonly windowService: WindowService,
        private readonly platformService: PlatformService,
        private readonly localStorageService: LocalStorageService,
        rendererFactory: RendererFactory2,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.document = this.windowService.getDocument();
        this.window = this.windowService.getWindowExtension();
    }

    public formSubmit(name: string): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'rokezzz_form_submit',
                'form': name,
                ...this.details(),
            });
        });
    }

    public login(): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'login',
                ...this.details(),
            });
        });
    }

    public logout(): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'logout',
                ...this.details(),
            });
        });
    }

    public register(): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'sign_up',
                ...this.details(),
            });
        });
    }

    public themeChange(): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'theme_change',
                ...this.details(),
            });
        });
    }

    public languageChange(): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'language_change',
                ...this.details(),
            });
        });
    }

    public analyticsEnable(enable: boolean): void {
        this.sendToAnalytics(() => {
            this.window.dataLayer.push({
                'event': 'analytics_enable',
                'cookie-ads': enable,
            });
        });
    }

    public init(): void {
        this.sendToAnalytics(() => { });
    }

    private details(): object {
        return {
            'locale': this.localeId,
            'theme': this.themeService.getTheme().class,
            'loggedUser': this.userService.getLoggedUser().value ? 'true' : 'false',
        }
    }

    private sendToAnalytics(onFinish: OnFinish<void>): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        if (this.googleAnalyticsLoaded) {
            onFinish();

            return;
        }

        if (this.googleAnalyticsLoading) {
            timer(1000).pipe(first()).subscribe(() => this.sendToAnalytics(onFinish));

            return;
        }

        this.googleAnalyticsLoading = true;

        timer(coreConfig.externalScriptLoadDelay).pipe(
            first()
        ).subscribe(() => {
            const scriptElement: HTMLElement = this.renderer.createElement('script'),
                inlineScript = this.renderer.createText(`
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${this.googleAnalyticsApiKey}');
            `);
            scriptElement.nonce = this.cspNonce;
            this.renderer.appendChild(scriptElement, inlineScript);
            this.renderer.appendChild(this.document.head, scriptElement);

            const noscriptElement: HTMLElement = this.renderer.createElement('noscript'),
                inlineNoscript = this.renderer.createText(`<iframe src="https://www.googletagmanager.com/ns.html?id=${this.googleAnalyticsApiKey}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`);

            noscriptElement.nonce = this.cspNonce;
            this.renderer.appendChild(noscriptElement, inlineNoscript);
            this.renderer.appendChild(this.document.body, noscriptElement);
            this.googleAnalyticsLoaded = true;

            if (this.localStorageService.getPreference().ads === 'enabled') {
                this.analyticsEnable(true);
            }
        });
    }
}
