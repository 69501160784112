import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { first, interval, Subscription, timer } from 'rxjs';
import { VisibilityService } from './visibility.service';
import { PlatformService } from 'src/app/core/services/platform.service';

@Injectable({
    providedIn: 'root'
})
export class PageLoaderService implements OnDestroy {
    private readonly updateInterval: number = 500;
    private readonly checkInterval: number = 100;

    private progress: number = 0;
    private shouldAnimate: boolean = true;
    private intervalSubscription?: Subscription;

    public constructor(
        private readonly visibilityService: VisibilityService,
        private readonly platformService: PlatformService,
        private readonly ngZone: NgZone,
    ) {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.intervalSubscription = interval(this.updateInterval).subscribe(() => {
                if (this.progress % 100 === 0 || !this.visibilityService.getWindowVisible()) {
                    return;
                }

                this.ngZone.run(() => this.updateProgress());
            });
        });
    }

    public ngOnDestroy(): void {
        this.intervalSubscription?.unsubscribe();
    }

    public start(): void {
        if (this.progress !== 0 || !this.shouldAnimate) {
            timer(this.checkInterval).pipe(
                first()
            ).subscribe(this.start.bind(this));

            return;
        }
        this.progress = 20;
    }

    public finish(): void {
        this.progress = 100;
        timer(this.updateInterval).pipe(
            first()
        ).subscribe(() => {
            this.shouldAnimate = false;
            this.progress = 0;
            timer(this.checkInterval).pipe(
                first()
            ).subscribe(() => {
                this.shouldAnimate = true;
            });
        });
    }

    public getProgress(): number {
        return this.progress;
    }

    public getShouldAnimate(): boolean {
        return this.shouldAnimate;
    }

    private updateProgress(): void {
        this.progress += (100 - this.progress) / 10;
    }
}
