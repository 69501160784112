import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PhoneOrMailComponent } from '../subform/phone-or-mail/phone-or-mail.component';
import { FormsModule } from '@angular/forms';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import { ContactService } from 'src/app/front/services/contact.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { first, timer } from 'rxjs';
import { PlatformService } from 'src/app/core/services/platform.service';
import { LocalStorageService } from 'src/app/cookie/services/local-storage.service';
import { WindowService } from 'src/app/core/services/window.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';
import { ButtonComponent } from '../button/button.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: '.ui-encouragement-window',
    standalone: true,
    imports: [
        MatIconModule,
        FormsModule,
        PhoneOrMailComponent,
        ButtonComponent,
        LoaderComponent
    ],
    templateUrl: './encouragement-window.component.html',
    styleUrl: './encouragement-window.component.scss'
})
export class EncouragementWindowComponent implements AfterViewInit {
    private readonly localStorageKey: string = 'encouragement-popup';
    private readonly timeGenerateDelay: number = 5000;
    private readonly timeShowDelay: number = 500;

    @ViewChild(PhoneOrMailComponent) public phoneOrMailComponent!: PhoneOrMailComponent;
    public isSubmitted: boolean = false;
    public isActive: boolean = false;
    public isRequired: boolean = false;
    public isLoading: boolean = false;

    private isFullyLoaded: boolean = false;

    public constructor(
        private readonly pageLoaderService: PageLoaderService,
        private readonly contactService: ContactService,
        private readonly notificationService: NotificationService,
        private readonly platformService: PlatformService,
        private readonly localStorageService: LocalStorageService,
        private readonly windowService: WindowService,
        private readonly googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.isFullyLoaded = true;

        if (this.platformService.isPlatformServer()) {
            return;
        }

        timer(this.timeGenerateDelay).pipe(
            first()
        ).subscribe(() => {
            this.isRequired = this.localStorageService.getItem(this.localStorageKey) === null;

            if (this.isRequired) {
                this.windowService.getDocument().body.style.setProperty('overflow', 'hidden');

                timer(this.timeShowDelay).pipe(
                    first()
                ).subscribe(() => {
                    this.isActive = true;
                })
            }
        });
    }

    public close(overlay: HTMLElement, container: HTMLElement): void {
        overlay.classList.add('closed');
        container.classList.add('closed');
        this.localStorageService.setItem(this.localStorageKey, '');
        this.windowService.getDocument().body.style.removeProperty('overflow');
    }

    public submit(): void {
        if (!this.isFullyLoaded || !this.phoneOrMailComponent.isValid() || this.isLoading) {
            return;
        }

        this.pageLoaderService.start();
        this.isLoading = true;

        this.contactService.sendPromotionMessage(
            {
                value: this.phoneOrMailComponent.getValue(),
            },
            () => {
                this.isSubmitted = true;
                this.isLoading = false;
                this.pageLoaderService.finish();
                this.googleAnalyticsService.formSubmit('encouragement-form');
                this.notificationService.addSuccessNotification($localize`Pomyślnie wysłano zapytanie o wycenę!`);
            },
            () => {
                this.isLoading = false;
                this.pageLoaderService.finish();
                this.notificationService.addServerError();
            },
        )
    }
}
