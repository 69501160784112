import { Injectable } from '@angular/core';
import { cookieItems } from '../models/cookie-items';
import { cookieLocalstorageItem } from '../api/cookie-localstorage-item';
import { localstorageItems } from '../models/localstorage-items';
import { cookieLocalstorageType } from '../api/cookie-or-localstorage-type';
import { CookiePreference } from '../api/cookie-preference';
import { cookieLocalstorageGroupType } from '../api/cookie-localstorage-group-type';
import { cookiePreferenceKey } from '../models/cookie-preference-key';
import { PlatformService } from 'src/app/core/services/platform.service';
import { cookiePreferenceAllDisabled } from '../models/cookie-preference-all-disabled';

@Injectable({
    providedIn: 'root'
})
export class CookieLocalstorageHelperService {
    private readonly cookieItems: cookieLocalstorageItem[] = cookieItems;
    private readonly localstorageItems: cookieLocalstorageItem[] = localstorageItems;

    public constructor(
        private readonly platformService: PlatformService
    ) { }

    public shouldBeRemoved(item: string, type: cookieLocalstorageType): boolean {
        let shouldBeRemoved: boolean = false;

        const preference: CookiePreference = this.getCookiePreference();

        if (type === 'cookie') {
            this.cookieItems.forEach((cookieGroup: cookieLocalstorageItem) => {
                cookieGroup.items.forEach((pattern: string) => {
                    if (this.matchesPattern(item, pattern) && this.isGroupDisabled(cookieGroup.group, preference)) {
                        shouldBeRemoved = true;
                    }
                });
            });
        } else {
            this.localstorageItems.forEach((localstorageGroup: cookieLocalstorageItem) => {
                localstorageGroup.items.forEach((pattern: string) => {
                    if (this.matchesPattern(item, pattern) && this.isGroupDisabled(localstorageGroup.group, preference)) {
                        shouldBeRemoved = true;
                    }
                });
            });
        }

        return shouldBeRemoved;
    }

    public getCookieItems(): cookieLocalstorageItem[] {
        return this.cookieItems;
    }

    public getLocalstorageItems(): cookieLocalstorageItem[] {
        return this.localstorageItems;
    }

    private matchesPattern(item: string, pattern: string): boolean {
        if (pattern.endsWith('.*')) {
            return item.startsWith(pattern.slice(0, -2));
        }

        return item === pattern;
    }

    private getCookiePreference(): CookiePreference {
        if (this.platformService.isPlatformServer()) {
            return cookiePreferenceAllDisabled;
        }

        const preference: string | null = localStorage.getItem(cookiePreferenceKey);

        return preference ? JSON.parse(preference) : {};
    }

    private isGroupDisabled(groupName: cookieLocalstorageGroupType, preference: CookiePreference): boolean {
        return preference[groupName] === 'disabled';
    }
}
