import { CSP_NONCE, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { OnFinish } from '../../core/api/on-finish';
import { NotificationService } from '../../core/services/notification.service';
import { ApiService } from '../../core/services/api.service';
import { environment } from 'src/environments/environment';
import { WindowService } from 'src/app/core/services/window.service';
import { PlatformService } from 'src/app/core/services/platform.service';
import { first, timer } from 'rxjs';

declare var grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService {
    private readonly recaptchaApiKey: string = environment.recaptchaApiKey;

    private recaptchaLoaded: boolean = false;
    private recaptchaLoading: boolean = false;
    private renderer: Renderer2;
    private document: Document;

    public constructor(
        private readonly rendererFactory: RendererFactory2,
        private readonly notificationService: NotificationService,
        private readonly apiService: ApiService,
        private readonly windowService: WindowService,
        @Inject(CSP_NONCE) private readonly cspNonce: string,
        private readonly platformService: PlatformService,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.document = this.windowService.getDocument();
    }

    public prepareTokenForAction(action: string, onSuccess: OnFinish<void>): void {
        this.loadRecaptcha(() => {
            grecaptcha.ready(() => {
                grecaptcha.execute(this.recaptchaApiKey, { action: action })
                    .then((token: string) => {
                        this.apiService.setRecaptchaToken(token);
                        onSuccess();
                    })
                    .catch(() => {
                        this.notificationService.addServerError();
                    });
            });
        });
    }

    private loadRecaptcha(onFinish: OnFinish<void>): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        if (this.recaptchaLoaded) {
            onFinish();

            return;
        }

        if (this.recaptchaLoading) {
            timer(1000).pipe(first()).subscribe(() => this.loadRecaptcha(onFinish));

            return;
        }

        this.recaptchaLoading = true;

        const scriptElement = this.renderer.createElement('script');

        this.renderer.setAttribute(scriptElement, 'src', `https://www.google.com/recaptcha/api.js?render=${this.recaptchaApiKey}`);
        scriptElement.defer = true;
        scriptElement.nonce = this.cspNonce;

        scriptElement.onload = () => {
            this.recaptchaLoaded = true;
            onFinish();
        };
        scriptElement.onerror = () => {
            this.recaptchaLoaded = false;
            this.notificationService.addServerError();
        };

        this.renderer.appendChild(this.document.body, scriptElement);
    }
}
